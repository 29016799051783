import React from 'react';

const OpeningHours = () => {
    return (
        <section id="newsletter" className="block spacer p-top-l">
            <div className="container demo-bg" style={{ display: "flex", justifyContent: "center" }}>
                <div className="row">
                    <div style={{ display: "flex", marginTop: "4rem" }} >
                            <div className="newsletter">
                                <div className="newsletter-title"><h2>Öppettider</h2></div>
                                <div className="newsletter-description">
                                    <p className="font-weight-bold">Måndag - Fredag</p>
                                    <p>10 - 18</p>
                                    <p className="font-weight-bold">Lördag</p>
                                    <p>10 - 14</p>
                                    <p className="font-weight-bold">Söndag</p><p>Stängt</p>
                                </div>
                                <div className="newsletter-shortcode"/>
                            </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default OpeningHours;
