import React from 'react';

const Contacts = () => {
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Adress</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>Åvägen 42, 412 51 Göteborg</p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Telefon</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>
                            <a href="tel:073-610 63 99">073-610 63 99</a>
                        </p>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-title">
                        <h6>Email</h6>
                    </div>
                    <div className="contacts-item-description">
                        <p style={ { marginTop: "1.2rem" }}>
                            <p>Celikskomakerigarda@hotmail.com</p>
                            <a href="mailto:Celikskomakerigarda@hotmail.com">Klicka här för att mejla oss</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
