import React from 'react';

const ModalMenuSecondary = () => {
    return (
        <nav className="menu-secondary">
            <ul className="nav">
                <li className="nav-item"></li>
            </ul>
        </nav>
    );
};

export default ModalMenuSecondary;
