import React from 'react';

const AboutOurShoes = () => {
    return (
        <section id="contact-us" className="block spacer">
            <div className="bg-image text-tertiary spacer p-top-xl p-bottom-xl">
                <div className="wrapper text-center">
                    <div className="title">
                        <h3 className="text-uppercase">Skoreparation via mejl eller telefon</h3>
                    </div>
                    <a href={ process.env.PUBLIC_URL + "/contacts" } className="btn btn-outline-tertiary text-uppercase">Kontakta oss</a>
                </div>
            </div>
        </section>
    );
};

export default AboutOurShoes;
