import React from 'react';

const AboutOurTeam = () => {
    return (
        <div id="our-team" className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr">Våra medarbetare</h2>
            </div>

            <div className="row gutter-width-sm with-pb-md">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member" style={{ paddingTop: "0rem" }}>
                        <div className="img" style={{ maxHeight: "50%", maxWidth: "50%", paddingTop: "0rem" }}>
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/SnKY3Nc/member-icon-png-6.png" alt="Yakob"/>
                            </div>
                        </div>

                        <div className="team-member-content" style={{ minHeight: "17rem" }}>
                            <div className="team-member-position">
                                <p>Skomakar specialist</p>
                            </div>

                            <h4 className="team-member-t-head">Yakob</h4>

                            <div className="team-member-description">
                                <p>En erfaren skomakare och specialist inom allt som berör skomakeri med över 25 års erfarenhet inom yrket. Ett gott öga för stil som skräddarsyr efter vad som passar i samråd med kunden.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/Celik-Skomakeri-497877820234232/"><i className="fab fa-facebook-f"/></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/celikskomakerigarda98/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img" style={{ maxHeight: "50%", maxWidth: "50%", paddingTop: "0rem" }}>
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/SnKY3Nc/member-icon-png-6.png" alt="Julius"/>
                            </div>
                        </div>

                        <div className="team-member-content" style={{ minHeight: "17rem" }}>
                            <div className="team-member-position">
                                <p>Skomakare/läder specialist</p>
                            </div>

                            <h4 className="team-member-t-head">Julius</h4>

                            <div className="team-member-description">
                                <p>Erfaren skomakare och specialist inom läder arbeten. Delaktig skomakare sen barnsben med mycket god kompetens inom yrket. Brinner för god service och ger kunden det bästa som önskas.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/Celik-Skomakeri-497877820234232/"><i className="fab fa-facebook-f"/></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/celikskomakerigarda98/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img" style={{ maxHeight: "50%", maxWidth: "50%", paddingTop: "0rem" }}>
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/SnKY3Nc/member-icon-png-6.png" alt="Dodje" />
                            </div>
                        </div>

                        <div className="team-member-content" style={{ minHeight: "17rem" }}>
                            <div className="team-member-position">
                                <p>Skomakare</p>
                            </div>

                            <h4 className="team-member-t-head">Dodje</h4>

                            <div className="team-member-description">
                                <p>Dodje är en nyvärvad extremt erfaren skomakare som kan allt inom skomakeri. Dodje har drivit eget skomakeri i 25 år och har väldigt bred kunskap.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/Celik-Skomakeri-497877820234232/"><i className="fab fa-facebook-f"/></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/celikskomakerigarda98/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img" style={{ maxHeight: "50%", maxWidth: "50%", paddingTop: "0rem" }}>
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/SnKY3Nc/member-icon-png-6.png" alt="Andreas"/>
                            </div>
                        </div>

                        <div className="team-member-content" style={{ minHeight: "17rem" }}>
                            <div className="team-member-position">
                                <p>Skomakare/knivslip specialist</p>
                            </div>

                            <h4 className="team-member-t-head">Andreas</h4>

                            <div className="team-member-description">
                                <p>Andreas är erfaren skomakare och specialist inom knivarbeten. Andreas har jobbat med skomakeri sedan barnsben och är väldigt erfaren inom handslipning med japanska slipstenar.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/Celik-Skomakeri-497877820234232/"><i className="fab fa-facebook-f"/></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/celikskomakerigarda98/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutOurTeam;
