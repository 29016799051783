import React from 'react';

const ContactsUs = () => {
    return (
        <section id="contact-us" className="block">
            <div className="bg-dark-brown spacer p-top-lg">
                <div className="wrapper text-center">
                    <div className="title">
                        <h3 style={{ color: "white" }} className="text-uppercase">Våra tjänster</h3>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactsUs;
