import React, { Component, Fragment } from 'react';
import SwipeBox from 'fslightbox-react';

class SwipeBoxComponent extends Component {

    state = {
        isVisible: false,
        slide: 0,
    };
    showSlide = ( slide, event ) => {
        event.preventDefault();
        this.setState( {
            isVisible: !this.state.isVisible,
            slide: slide
        } );
    };

    render() {
        return (
            <Fragment>
                <SwipeBox
                    toggler={ this.state.isVisible }
                    slide={ this.state.slide }
                    urls={ [
                        'https://i.ibb.co/4tjxXbM/IMG12.jpg',
                        'https://i.ibb.co/tbyQXqf/IMG-2462.jpg',
                        'https://i.ibb.co/x2s139D/IMG-2460.jpg',
                        'https://i.ibb.co/FXc9f4R/af32ff65-5649-413a-8464-681c6a5157c4.jpg'
                    ] }
                />

                {this.props.render === false && <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 1, event ) }
                        title="Titel 1"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/4tjxXbM/IMG12.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/4tjxXbM/IMG12.jpg" alt="Bild 1" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true ? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 2, event ) }
                        title="Titel 2"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/tbyQXqf/IMG-2462.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/tbyQXqf/IMG-2462.jpg" alt="Bild 2" />
                            </div>
                        </div>

                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 3, event ) }
                        title="Titel 3"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/x2s139D/IMG-2460.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/x2s139D/IMG-2460.jpg" alt="Bild 3" />
                            </div>
                        </div>
                        <div className="img-bg-color"/>
                    </a>
                </div>}

                {this.props.render === false && <div className={this.props.render === true? "col-xl-4 col-lg-4 col-md-4 col-sm-4" : "col-xl-6 col-lg-6 col-md-6 col-sm-6"}>
                    <a
                        onClick={ ( event ) => this.showSlide( 4, event ) }
                        title="Titel 4"
                        className="gallery-item swipebox"
                        href="https://i.ibb.co/FXc9f4R/af32ff65-5649-413a-8464-681c6a5157c4.jpg"
                        rel="gallery"
                    >
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="https://i.ibb.co/FXc9f4R/af32ff65-5649-413a-8464-681c6a5157c4.jpg" alt="Bild 4" />
                            </div>
                        </div>
                        <div className="img-bg-color"/>
                    </a>
                </div>}
            </Fragment>
        );
    };
};

export default SwipeBoxComponent;
