import React, { Fragment } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ContactsUs from "../blocks/contacts/ContactUs";
import ServicesHome from '../blocks/services/ServicesHome';
import AboutsUs from '../blocks/about/AboutUs';
import OpeningHours from '../blocks/opening-hours/Opening-hours';
import GoogleMapsIframe from '../blocks/google-maps/GoogleMapsIframe';
import ContactInfo from '../blocks/contact-info/contact-info';
import AboutOurShoes from '../blocks/about/AboutOurShoes';
import AboutOurClients from '../blocks/about/AboutOurClients';
import News from '../blocks/ShoeNews/News';


const Home = () => {
    document.body.classList.add( 'home' );
    document.body.classList.add( 'header-absolute-true' );

    return (
        <Fragment>
            <MetaTags>
                <meta charSet="UTF-8" />
                <title>Celik skomakeri | Gårda</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Celik skomakeri hemsida goteborg" />
                <meta name="keywords" content="Celik skomakeri gårda skomakeri gårda göteborg celik göteborg skomakeri göteborgs bästa skomakeri celik skor celik skomakare skomakare celik skomakeri celik skomakeri gårda skomakeri gårda celik" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </MetaTags>

            <Loading />

            <Header logoColor="light" />

            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />

                        <ContactsUs />

                        <ServicesHome />

                        <AboutsUs />

                        <AboutOurClients />

                        <News />

                        <AboutOurShoes />

                        <OpeningHours/>

                        <GoogleMapsIframe />

                        <ContactInfo />
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
