import React, { Component } from 'react';
import Swiper from 'swiper';

class ServicesHome extends Component {
    componentDidMount() {
        new Swiper( '.adv-slider-services .adv-swiper-container', {
            // ADV swiper
            noSwipingClass: 'adv-swiper-no-swiping',
            containerModifierClass: 'adv-swiper-container-',
            slideClass: 'adv-swiper-slide',
            slideBlankClass: 'adv-swiper-slide-invisible-blank',
            slideActiveClass: 'adv-swiper-slide-active',
            slideDuplicateActiveClass: 'adv-swiper-slide-duplicate-active',
            slideVisibleClass: 'adv-swiper-slide-visible',
            slideDuplicateClass: 'adv-swiper-slide-duplicate',
            slideNextClass: 'adv-swiper-slide-next',
            slideDuplicateNextClass: 'adv-swiper-slide-duplicate-next',
            slidePrevClass: 'adv-swiper-slide-prev',
            slideDuplicatePrevClass: 'adv-swiper-slide-duplicate-prev',
            wrapperClass: 'adv-swiper-wrapper',
            navigation: {
                disabledClass: 'adv-swiper-button-disabled',
                hiddenClass: 'adv-swiper-button-hidden',
                lockClass: 'adv-swiper-button-lock',
                nextEl: '.adv-2-swiper-button-next',
                prevEl: '.adv-2-swiper-button-prev'
            },
            spaceBetween: 0,
            slidesPerView: 'auto'
        } );
    }

    render() {
        return (
            <section id="services" className="block">
                <div className="adv-slider-services">
                    <div className="adv-swiper-container">
                        <div className="adv-swiper-wrapper services-items clearfix">
                            <a title="Skoreparation" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/services" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Skoreparation</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">Läs mer</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img
                                            src="https://i.ibb.co/JBL6drz/skoreparation.jpg"
                                            alt="Skoreparation"
                                        />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Nyckelservice" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/services" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Nyckelservice</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">Läs mer</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img
                                            src="https://i.ibb.co/Y2gZRRR/locksmith-duplicate-machine-make-new-key.jpg"
                                            alt="Nyckelservice"
                                        />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Kemtvatt" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/services" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Kemtvätt</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">Läs mer</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img
                                            width="100%"
                                            src="https://i.ibb.co/qjKRBDp/Clothes-hanging-in-the-laundrette.jpg"
                                            alt="Kemtvätt"
                                        />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="knivarsaxar" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/services" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Slipning knivar & saxar</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">Läs mer</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="https://i.ibb.co/Kh7tmWg/Knife-sharpening-Hands-holding-knife-and-whetstone-on-the-old-wooden-cutting-board.jpg"
                                             alt="Slipning av knivar och saxar"
                                        />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Batteribyten" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/services" }>
                                <div className="services-item-content">
                                        <h3 className="services-item-t-head">Batteribyten</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">Läs mer</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img
                                            src="https://i.ibb.co/KNPtxBQ/Close-up-Of-Tweezers-Placing-Battery-On-Golden-Wrist-Watch.jpg"
                                            loading="lazy"
                                            alt="Batteribyten" />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>

                            <a title="Skyltgravyr" className="adv-swiper-slide services-item" href={ process.env.PUBLIC_URL + "/services" }>
                                <div className="services-item-content">
                                    <h3 className="services-item-t-head">Skyltgravyr</h3>

                                    <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">Läs mer</span>
                                </div>

                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img
                                            src="https://i.ibb.co/hx579fB/Close-up-of-laser-engraving-and-cutting-machine-and-just-finished-heart-shaped-wooden-badge.jpg"
                                            loading="lazy"
                                            alt="Skyltgravyr"
                                        />
                                    </div>
                                </div>

                                <div className="img-bg-color"></div>
                            </a>
                        </div>

                        <div className="adv-2-swiper-button-prev">
                            <span>
                                <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">Föregående</span>
                            </span>
                        </div>

                        <div className="adv-2-swiper-button-next">
                            <span>
                                <span className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">Nästa</span>
                            </span>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ServicesHome;
