import React from 'react';
import { Link } from 'react-scroll';

const PageTitleHome = () => {
    return (
        <section id="page-title" className="block with-img" style={ window.innerWidth < 800 ? {  backgroundPosition: "50%" } : {} }>
                <div className="wrapper text-center d-flex">
                    <div className="align-self-center w-100">
                        <div className="title">
                            <h1 className="large text-uppercase">Celik skomakeri</h1>
                        </div>

                        <div className="description spacer p-top-lg">
                            <h4>Gårda</h4>
                        </div>

                        <div className="spacer p-top-lg no-space">
                            <Link href="#contact-us"
                                  to="contact-us"
                                  spy={ true }
                                  smooth={ true }
                                  duration={ 0 }
                                  className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id">
                                <i className="fas fa-caret-down fa-4x"/>
                            </Link>
                        </div>
                    </div>
                </div>
            <div className="page-title-bg-color"></div>
        </section>
    );
};

export default PageTitleHome;
