import React from 'react';

const ContactInfo = () => {
    return (
        <section id="newsletter" className="block spacer p-top-l">
            <div className="container demo-bg" style={{ display: "flex", justifyContent: "center"}}>
                <div className="row"  style={{ marginTop: "4rem", marginBottom: "2rem" }}>
                    <div className="col-lg-4" style={{ marginTop: "2rem", display: "block", textAlign: "center" }}>
                        <h2>Adress</h2>
                        <a href="https://goo.gl/maps/2WZFPeM5h8jaNa8N7" style={{ color: "#6c6c6c"}}>Åvägen 42, 412 51 Göteborg</a>
                    </div>
                    <div className="col-lg-4" style={{ marginTop: "2rem", flexWrap: "inherit", display: "flex", justifyContent: "center" }}>
                        <img className="logo logo-secondary header-logo" style={{ maxHeight: "100px", objectFit: "contain", marginTop: "-30px", display: "flex", justifyContent: "center" }} src="https://i.ibb.co/YLpNc8Q/circle-cropped-removebg-preview-1.png" alt="Celik" />

                        <div><p>Öka livslängden på dina favorit skor genom att låta vår proffs återföra dem till liv.</p></div>
                    </div>
                    <div className="col-lg-4" style={{ marginTop: "2rem", display: "block", textAlign: "center", marginBottom: "4rem" }}>
                        <h2>Telefon</h2>
                        <div><p>073-610 63 99</p></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactInfo;
