import React from 'react';

const HeaderLogo = ( { logoColor } ) => {
    return (
        <div className="header-logo">
            <a className="logo logo-secondary" title="Logo" href={ process.env.PUBLIC_URL + "/" }>
                <img src="https://i.ibb.co/YLpNc8Q/circle-cropped-removebg-preview-1.png" alt="Logo" />
            </a>
        </div>
    );
};

export default HeaderLogo;
