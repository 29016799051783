import React from 'react';
import NewsHomeItemsData from '../../data/news/newsHomeItems';

const News = () => {
    return (
        <section id="news" className="block spacer p-top-xl p-bottom-xl">
            <div className="wrapper text-center">
                <div className="title">
                    <h2 className="hr text-uppercase">Skovård av hög kvalitet</h2>
                </div>
                <div className="row gutter-width-sm with-pb-lg">
                    { NewsHomeItemsData && NewsHomeItemsData.map( ( item, key ) => {
                        return (
                            <div key={ key } className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="object-fit-cover transform-scale-h" style={{ marginBottom: "2rem", maxHeight: "10rem" }}>
                                    <img
                                        className="card-top-img"
                                        style={ item.id === "2" ? { maxHeight: "50%", maxWidth: "40%", minWidth: "40%" } : item.id === "1" ? { maxHeight: "100%", maxWidth: "42%", minWidth: "42%" } : { maxHeight: "100%", maxWidth: "62%", minWidth: "62%" } }
                                        src={ item.imageLink }
                                        loading="lazy"
                                        alt=""
                                    />
                                </div>
                                <div className="card card-post">
                                    <div className="card-body">
                                        <h4 className="card-title">
                                            { item.title }
                                        </h4>

                                        <p className="card-text">{ item.description }</p>
                                    </div>
                                </div>
                            </div>
                        );
                    } ) }
                </div>
            </div>
        </section>
    );
};

export default News;
