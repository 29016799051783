import React from 'react';

const AboutsUs = () => {
    return (
        <section id="about-us" className="block spacer p-top-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h3 className="text-uppercase">Om du älskar dina skor och vill ha en lång livslängd på skorna krävs kärlek tillbaka i form av underhållning, reparation och uppfräschning som vi kan hjälpa till med.</h3>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm justify-content-end">
                    <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                        <div className="description">
                            <p>
                                Vårt huvudmål är nöjda kunder och vi gör allt vi kan för att vara tydliga och lyhörda för deras behov. När du lämnar in dina produkter gör vi vårt bästa för att erbjuda kvalitetsarbete till rimliga priser.
                                Vi är professionella med över 25 år lång erfarenhet inom våra tjänster. Vi är stolta över att majoriteten av kunder som har besökt oss en gång vanligtvis beslutar att återvända
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutsUs;
