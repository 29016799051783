import React from 'react';

const AboutOurClients = () => {
    return (
        <section id="contact-us" className="block spacer p-top-xl">
            <div className="bg-image-1 text-tertiary spacer p-top-xl p-bottom-xl">
                <div className="wrapper text-center">
                    <div className="title">
                        <h3 className="text-uppercase">Våra kunders feedback</h3>
                    </div>
                    <div>
                        <i style={{ marginBottom: "1.2rem" }} className="fas fa-quote-right" />
                        <div><p>Jag är jättenöjd med servicen här. Alltid trevlig, hjälpsam, och informerar gärna om hur han går tillväga vid reparationer och hur jag ska sköta mina skor. Jag har lämnat in många skor här och jag har aldrig blivit missnöjd varken med tid, kvalitet eller kostnad.</p></div>
                    </div>
                    <div>
                        <i style={{ marginBottom: "1.2rem", marginTop: "2.4rem" }} className="fas fa-quote-right" />
                        <div><p>Här får du otrolig service och ett gott bemötande! Har bara positiva saker att säga om stället!</p></div>
                    </div>
                    <div>
                        <i style={{ marginBottom: "1.2rem", marginTop: "2.4rem" }} className="fas fa-quote-right" />
                        <div><p style={ { lineBreak: "auto" }}>Hjälpte mig med dragkedjan på ett kick. Proffsig och vänlig! Hit vänder jag mig igen.</p></div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutOurClients;
