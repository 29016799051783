import React from 'react';

const GoogleMapsIframe = () => {
    return (
        <iframe
            title="Celik skomakeri karta"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8528.824437402935!2d11.97364324681281!3d57.695968047877436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464ff39cfe751531%3A0xded669d3605e5603!2sCelik%20Skomakeri%20G%C3%A5rda%20AB!5e0!3m2!1ssv!2sse!4v1623101984930!5m2!1ssv!2sse"
            width="100%"
            height="500"
            frameBorder="0"
            allowFullScreen=""
            aria-hidden="false"
            loading="lazy"
            tabIndex="0">
        </iframe>
    );
};

export default GoogleMapsIframe;
