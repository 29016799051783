import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Gallery from './pages/Gallery';
import Services from './pages/Services';
import page404 from './pages/404';
import { QueryClient, QueryClientProvider } from "react-query";



function App() {
    const queryClient = new QueryClient();

    return (
            <QueryClientProvider client={queryClient}>
                <Router>
                    <Switch>
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/"}`}
                            component={Home}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/about-us"}`}
                            component={About}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/contacts"}`}
                            component={Contacts}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/gallery"}`}
                            component={Gallery}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL + "/services"}`}
                            component={Services}
                        />
                        <Route exact component={page404} />
                    </Switch>
                </Router>
            </QueryClientProvider>
    );
}

export default App;
